<script setup lang="ts">
import useConfig from '../composables/useConfig.ts';
import { SourceLibrary } from '../src/classes.ts';

const config = useConfig();

const key = config.pronouns.enabled && config.pronouns.null && config.pronouns.null.routes
    ? config.pronouns.null.routes[0]
    : null;

const { data: sourcesRaw } = useAsyncData(async () => {
    if (!key) {
        return;
    }

    return await $fetch(`/api/sources`, { query: { locale: config.locale, pronoun: key } });
});

const sources = computed(() => {
    if (!key || !sourcesRaw.value) {
        return;
    }

    return {
        '': new SourceLibrary(config, sourcesRaw.value).getForPronoun(key),
    };
});
</script>

<template>
    <div v-if="config.pronouns.null && config.pronouns.null.ideas && config.pronouns.null.ideas.length">
        <section v-for="idea in config.pronouns.null.ideas">
            <h3>
                {{ idea.header }}
            </h3>
            <p v-if="idea.description">
                {{ idea.description }}
            </p>
            <p v-if="idea.normative">
                <NormativeBadge />
            </p>
            <ul>
                <li v-for="[exampleFrom, exampleTo] in idea.examples">
                    <LinkedText :text="exampleFrom" /> → <strong><LinkedText :text="exampleTo" /></strong>
                </li>
            </ul>
        </section>

        <section v-if="sources && sources[''].length">
            <Literature :sources="sources" />
        </section>
    </div>
</template>
